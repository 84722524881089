import React from "react";

import { Route, NavLink } from "react-router-dom";
import { adminLogOut } from "../../redux/actions/admin";
import "./admin/home/styles.css";
import { useSelector, useDispatch } from "react-redux";
import AdminBuyBack from "../PrivatePages/admin/dashboard-buyback/index";
import { useHistory } from "react-router-dom";
import niob_logo from "../../Images/Niob-Header-Logo.png";
import AdminTable from "./admin/dashboard-tables";
import Adminbsc from "./admin/dashboard-bsc/dashboardBSC";
import Modal from "./admin/dashboard-popup";

const AdminDashboard = () => {
  const  setShow = false;
  const handleShow = () => setShow(true);
  const history = useHistory();

  const metaMaskAddress = useSelector((state) => state.admin.adminAddress);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(adminLogOut(history));
  };
  return (
    <div>
      <div className="main">
        <aside>
          <header className="header">
            <nav className="navbar navbar-toggleable-md navbar-light  admin-header">
              <a className="navbar-brand" href="buyback">
                <img src={niob_logo} alt="" />
              </a>
              <button
                className="navbar-toggler navbar-toggler-right hidden-mobile"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              ></button>
              <ul className="navbar-nav ml-auto topbar-menu">
                <li className="nav-item">
                  <a className="nav-link address" href="buyback">{metaMaskAddress}</a>
                </li>
                <li>
                  <Modal
                    onClick={() => handleShow(true)}
                    to="/"
                    className="admin_popup fw-normal d-flex"
                    title="Popup"
                  />
                </li>
                <li className="nav-item logout_btn">
                  <a href="admin-login"
                    className="nav-link"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => logOut()}
                  >
                    <i className="fa fa-fw fa-sign-out"></i>Logout
                  </a>
                </li>
              </ul>
            </nav>
          </header>
          <div className="sidebar left">
            <nav className="navbar navbar-expand-lg navbar-light">
              <button
                className="navbar-toggler left-bar-toggle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="list-sidebar bg-defoult ">
                  {/* <li>
                    {" "}
                    <NavLink
                      to="/admin-dashboard"
                      exact
                      activeClassName="active"
                    >
                      <i className="fa fa-ils"></i>{" "}
                      <span className="nav-label">Dashboard</span>
                    </NavLink>{" "}
                  </li> */}
                  <li>
                    {" "}
                    <NavLink
                      to="/admin-dashboard/buyback"
                      activeClassName="active"
                    >
                      <i className="fa fa-ils"></i>{" "}
                      <span className="nav-label">Dashboard</span>
                    </NavLink>{" "}
                  </li>

                  <li>
                    {" "}
                    <NavLink to="Tables" activeClassName="">
                      <i className="fa fa-ils"></i>{" "}
                      <span className="nav-label">Table</span>
                    </NavLink>{" "}
                  </li>

                  <li>
                    {" "}
                    <NavLink to="BSC" activeClassName="">
                      <i className="fa fa-ils"></i>{" "}
                      <span className="nav-label">BSC</span>
                    </NavLink>{" "}
                  </li>
                  {/* <li>
                    {" "}
                    <NavLink
                      
                      to="/admin-dashboard/charity"
                      activeClassName="active"
                    >
                      <i className="fa fa-ils"></i>{" "}
                      <span className="nav-label">Charity</span>
                    </NavLink>{" "}
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>

          <div className="main-container-dashboard staked-border">
            <Route
              exact
              path="/admin-dashboard/buyback"
              component={AdminBuyBack}
            />
            
            
            <Route
              path="/admin-dashboard/tables"
              component={AdminTable}
            />
            <Route
              path="/admin-dashboard/bsc"
              component={Adminbsc}
            />
            
          </div>
        </aside>
      </div>
    </div>
  );
};

export default AdminDashboard;
