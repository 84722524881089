import React from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { adminLogin } from "../../../../redux/actions/admin";
import { withRouter, useHistory } from "react-router-dom";

const Index = (props) => { 
  const dispatch = useDispatch();
  const history = useHistory();

  const doLogin = async (e) => {
    e.preventDefault();
    dispatch(adminLogin(history));
  };

 ;

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-2"></div>
        <div className="col-lg-6 col-md-6 login-box">
          <div className="row"> 
            <div className="col-lg-12 login-key">
              {/* <img src={logo} /> */}
            </div>
            <div className="col-lg-12 login-form">
              <div className="col-lg-12 login-form"> 

                <div className="col-lg-12 loginbttm">
                  <div className="col-lg-6 login-btm login-text"></div>
                  <div className="col-lg-12 login-btm login-button">
                    <button
                      onClick={(e) => doLogin(e)} 
                      className="btn btn-outline-primary"
                    >
                     ADMIN LOGIN
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Index);
