import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Form } from "react-bootstrap";
import ButtonCustom from "../../../../Components/ButtonCustom/ButtonCustom";
import { addFunction } from "../../../../redux/actions/admin.action";
import MASTERCHEFABI from "../../../../assets/ABI/masterchef.json";
import { MASTERCHEF_INPUTS } from "../../../../constant";
import "./bsc.scss";

const DashboardBSC = () => {
  const dispatch = useDispatch();

  const [classToggle, setClassToggle] = useState('');
  const [methods, setMethods] = useState([]);

  const handleSubmit = (index) => {
    const data = {
      name: methods[index]?.name,
      params: methods[index]?.inputs
    }
    dispatch(addFunction(data));

  }
  const masterChef = async () => {
    let o = [];
    for (var i = 0; i < MASTERCHEFABI.length; i++) {
      if (MASTERCHEFABI[i].name !== undefined && MASTERCHEFABI[i].stateMutability !== 'view' && MASTERCHEFABI[i].stateMutability !== 'pure') {
        let obj = {
          name: MASTERCHEFABI[i].name,
          inputs: MASTERCHEFABI[i].inputs,
        }
        o.push(obj);
      }
      setMethods(o);
    }
  }
  const handleInputValues = (e, index, name, inputIndex) => {

    methods[index].inputs[inputIndex].value = e.target.value;

  }
  const handleInputs = (data, index) => {
    const values = Object.values(MASTERCHEF_INPUTS[index]);
    return data && data.map((item, i) => <Form.Group>
      <Form.Label className="eth" key={i}>
        {item.name} {item.internalType}
      </Form.Label>
      <input
        placeholder={item.name}
        onChange={(event) =>
          handleInputValues(event, index, item.name, i)
        }
        value={values[i]?.value}
        required
      />
    </Form.Group>)
  }
  const handleState = (name, index) => {
    if (!classToggle) {
      setClassToggle(name + index);
      return;
    }
    setClassToggle("");
  }
  useEffect(() => {

    async function fetchMyAPI() {
      await masterChef();
    }

    fetchMyAPI()
  }, []);
  return (
    <>
      <Container className="full-width-container admin-form">
        <Row className="admin-row justify-content-center">
          {
            methods.length > 0 && methods.map((data, index) => (
              <div className="col-md-12 bsc_sec p-0">
                <div
                  className="planet_bar"
                  onClick={() => handleState(data.name, index)}
                >
                  <div className="available_funds">{data.name}</div>
                </div>
                <div className={classToggle === data.name + index ? "planet_strip" : "d-none"}>
                  <div className="available_funds">
                    <div className="funds">
                      {" "}
                      <Form.Group>
                        <Form.Label className="eth">
                          Function Name:
                        </Form.Label>
                        <input
                          value={data.name}
                          readOnly
                        />
                      </Form.Group>
                      {handleInputs(data.inputs, index)}
                      <ButtonCustom title="Add" className="rounded-pill" onClick={() => handleSubmit(index)} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default DashboardBSC;
