import { EthereumService } from "../../services/EthereumService";
import { USER_ADDRESS, USER_THEME } from "./user.action";
import { toast } from "../../Components/Toast/Toast";
import { startLoading, stopLoading } from "./loading.action";
import * as session from "../../utils/session";

export function callWeb3() {
    return (dispatch, getState) => {
        return EthereumService.callWeb3();
    };
}

export const isMetaMaskInstalled = async () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum, web3 } = window;
    const result = Boolean(ethereum && ethereum.isMetaMask);
    if (result) {
        return "metamask";
    } else if (web3) {
        return "trustwallet";
    } else {
        return "none";
    }
};
export const connectMetamask = () => async (dispatch, getState) => {
    const installed = await isMetaMaskInstalled();
    try {
        let address;
        if (installed === "metamask") {
            const { ethereum } = window;
            ethereum
                .enable()
                .then(async (accounts) => {
                    address = accounts[0];
                    return dispatch({ type: USER_ADDRESS, payload: address });
                })
                .catch((err) => console.log(err));

            ethereum.on('accountsChanged', function (accounts) {
                if (window.location.pathname.indexOf('admin') !== -1) {
                    session.clearSession();
                    window.location = '/admin-login'
                }
                address = accounts[0];
                return dispatch({ type: USER_ADDRESS, payload: address });
            })

            ethereum.on('networkChanged', function (networkId) {
                window.location.reload();
            });
        }
        else {
            return toast.error("Please connect metamask");
        }
    } catch (error) {
        return toast.error(error.message);
    }
};

export function changeTheme(lightMode) {
    return async (dispatch, getState) => {
        try {
            return dispatch({ type: USER_THEME, payload: lightMode });
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(error.message);
        }
    };
}

export function getTokenStakedAmount() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const tokenStakedAmount = await EthereumService.getTokenStakedAmount()
            dispatch(stopLoading());
            return tokenStakedAmount
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(error.message);
        }
    };
}



