const adminType = "accessToken";
const accessAdminTokenKey = "accessAdminTokenKey";
const theme = "theme";


export const setAdminSession = (accessToken) => {
  localStorage.setItem(accessAdminTokenKey, accessToken);
  return true
};

export const setLightThemeMode = (mode) => {
  localStorage.setItem(theme, mode);
  return true
};

export const getLightThemeMode = async () => {
  let lightMode = await localStorage.getItem(theme);
  return lightMode === 'false' ? false : true;
};

export const getAdminToken = () => {
  let token = localStorage.getItem(accessAdminTokenKey);
  return token
};

export const setAdminType = (loggedInAdminType) => {
  localStorage.setItem(adminType, loggedInAdminType);
  return true
};

export const getAdminType = () => {
  let token = localStorage.getItem(adminType);
  return token
};

export const clearSession = () => {
  localStorage.removeItem(accessAdminTokenKey);
  localStorage.removeItem(adminType);
};

