// import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/reducers/history";
import { PersistGate } from "redux-persist/lib/integration/react";
import LoaderComponent from "./Components/LoaderCompoent/LoaderCompoent";
import Application from "./Application";
let { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <LoaderComponent />
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Application></Application>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
