import Web3 from "web3";
import buyBackContractABI from "../assets/BuyBackContractABI.json";
import CharityContractABI from "../assets/CharityContractABI.json";
import FarmPoolContractABI from "../assets/FarmPoolABI.json";
import MultiTokenABI from "../assets/MultiTokenABI.json";
import dynamicContractABI from "../assets/DynamicABI.json";

import {
    XIVDB_ADDRESS, CHARITY_CONTRACT_ADDRESS,
    BUYBACK_CONTRACT_ADDRESS, NETWORK, BUYBACK_CONTRACT_OWNER,
    FARM_POOL_CONTRACT_ADDRESS, MULTI_TOKEN_CONTRACT_ADDRESS
} from "../constant"
import BigNumber from 'big-number';
import { ContractServices } from "./ContractServices";
import { toast } from "../Components/Toast/Toast";

let web3Instance, buyBackContractInstance,
    charityContractInstance, farmPoolContractInstance, multiTokenContractInstance;

const callWeb3 = () => {
    return new Promise(async (resolve, reject) => {
        resolve(web3Instance);
    });
};

const callGlobalFunction = async () => {
    if (!web3Instance) {
        const { ethereum } = window;
        if (ethereum && ethereum.isMetaMask) {
            web3Instance = new Web3(ethereum);
            web3Instance.eth.net.getNetworkType((err, network) => {
                if (network !== NETWORK) {
                    return false;
                }
            })
            buyBackContractInstance = new web3Instance.eth.Contract(
                buyBackContractABI,
                BUYBACK_CONTRACT_ADDRESS
            );
            charityContractInstance = new web3Instance.eth.Contract(
                CharityContractABI,
                CHARITY_CONTRACT_ADDRESS
            );
            farmPoolContractInstance = new web3Instance.eth.Contract(
                FarmPoolContractABI,
                FARM_POOL_CONTRACT_ADDRESS
            );
            multiTokenContractInstance = new web3Instance.eth.Contract(
                MultiTokenABI,
                MULTI_TOKEN_CONTRACT_ADDRESS
            );
            
        } else if (window.web3) {
            web3Instance = new Web3(window.web3.currentProvider);
        } else {

        }
    }
    // })
};
callGlobalFunction()

const createBuyBackContractInstance = () => {
    return new Promise(async (resolve, reject) => {
        if (buyBackContractInstance) {
            resolve(buyBackContractInstance);
        } else {
            reject();
        }
    });
};

const createCharityContractInstance = () => {
    return new Promise(async (resolve, reject) => {
        if (charityContractInstance) {
            resolve(charityContractInstance);
        } else {
            reject();
        }
    });
};

const createFarmPoolContractInstance = () => {
    return new Promise(async (resolve, reject) => {
        if (farmPoolContractInstance) {
            resolve(farmPoolContractInstance);
        } else {
            reject();
        }
    });
};

const createMultiTokenContractInstance = () => {
    return new Promise(async (resolve, reject) => {
        if (multiTokenContractInstance) {
            resolve(multiTokenContractInstance);
        } else {
            reject();
        }
    });
};



const getCharityAddress = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.charityAddress().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getDevAddress = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.devAddress().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getSwapAllowance = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.allowSwap().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getStakingPhase = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createCharityContractInstance();
            if (contract.methods) {
                contract.methods.stakingPhase().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getCharityRound = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createCharityContractInstance();
            if (contract.methods) {
                contract.methods.charityRound().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getPoolLength = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createFarmPoolContractInstance();
            if (contract.methods) {
                contract.methods.poolLength().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getPoolInfo = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const poolLength = await getPoolLength();
            const contract = await createFarmPoolContractInstance();
            if (contract.methods) {
                for (let i = 0; i < poolLength; i++) {
                    contract.methods.poolInfo(i).call().then((result) => {
                        console.log("result number ", i, result);
                        resolve(result);
                    })
                        .catch((error) => {
                            resolve(error);
                        });
                }

            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};


const addFarmPool = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const harvestInterval = data.harvestHours * 3600 + data.harvestMinutes * 60;
            const contract = await createFarmPoolContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();
            console.log("going throug...1");
            const gas = await contract.methods.add(data.multiplier, data.tokenAddress, data.depositFee, harvestInterval, false, data.tokenType).estimateGas({ from: BUYBACK_CONTRACT_OWNER });

            console.log("going throug...2");

            if (contract.methods) {

                console.log("going throug...3");

                contract.methods.add(data.multiplier, data.tokenAddress, data.depositFee, harvestInterval, false, data.tokenType)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error:', error)
            reject(error);
        }
    });
};


const addToken = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createMultiTokenContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();
            let isTokenBlackListed;
            if (contract.methods) {
                const result = await contract.methods.isBlacklisted(data.tokenAddress).call();
                if (result === true) { isTokenBlackListed = true }
                if (result === false) { isTokenBlackListed = false }
            }

            if (!isTokenBlackListed) {
              
                const gas = await contract.methods.whiteListTokens([data.tokenAddress], data.transferTaxed === 'true' ? true : false).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
                if (contract.methods) {
                    contract.methods.whiteListTokens([data.tokenAddress], data.transferTaxed === 'true' ? true : false)
                        .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                        .then((result) => {
                            resolve(result);
                        })
                        .catch((error) => {
                            resolve(error);
                        })
                } else {
                    reject(new Error("Contract not found."));
                }
            } else toast.error('Token is BlackListed')
        } catch (error) {
            console.log('error:', error)
            reject(error);
        }
    });
};

const blackListToken = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createMultiTokenContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();
            console.log("going throug...1");
            const gas = await contract.methods.blacklistToken(data.tokenAddress, true).estimateGas({ from: BUYBACK_CONTRACT_OWNER });

            console.log("going throug...2");

            if (contract.methods) {

                console.log("going throug...3");

                contract.methods.blacklistToken(data.tokenAddress, true)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        console.log('yess, blacklisted', result);
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const whiteListToken = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createMultiTokenContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();
            console.log("going throug...1");
            const gas = await contract.methods.blacklistToken(data.tokenAddress, false).estimateGas({ from: BUYBACK_CONTRACT_OWNER });

            console.log("going throug...2");

            if (contract.methods) {

                console.log("going throug...3");

                contract.methods.blacklistToken(data.tokenAddress, false)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        console.log('yess, blacklistedss', result);
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getDevShare = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.devShare().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getNumMin = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.numMin().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getNumMax = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.numMax().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};

const getBalance = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.balance().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};


const getRandomNumber = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            if (contract.methods) {
                contract.methods.randomResult().call().then((result) => {
                    resolve(result);
                })
                    .catch((error) => {
                        resolve(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error111111111', error)
            reject(error);
        }
    });
};


const changeSwapAllowance = (value) => {
    console.log("change swap allowance to", value);
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.changeSwapAllowance(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.changeSwapAllowance(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }

        } catch (error) {
            console.log("error", error);
            reject(error);
        }
    });
};

const stopStakingPhase = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createCharityContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.stopStakingPhase().estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.stopStakingPhase()
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }

        } catch (error) {
            console.log("error", error);
            reject(error);
        }
    });
};

const startStakingPhase = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createCharityContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.startStakingPhase().estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.startStakingPhase()
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }

        } catch (error) {
            console.log("error", error);
            reject(error);
        }
    });
};

const approveCharity = (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createCharityContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.addProject(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.addProject(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }

        } catch (error) {
            console.log("error", error);
            reject(error);
        }
    });
};

const setDevShare = (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.setDevShare(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.setDevShare(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            reject(error);
        }
    });
};


const setNumMin = (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.setNumMin(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.setNumMin(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            reject(error);
        }
    });
};

const setNumMax = (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log("you called me and provided value", value);
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.setNumMax(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.setNumMax(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            reject(error);
        }
    });
};

const setNumMinLottery = (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.setNumMin(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.setNumMin(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            reject(error);
        }
    });
};

const setNumMaxLottery = (value) => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log("you called me and provided value", value);
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.setNumMax(value).estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.setNumMax(value)
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            reject(error);
        }
    });
};

const emergencyWithdrawal = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = await createBuyBackContractInstance();
            const gasPrice = await ContractServices.calculateGasPrice();

            const gas = await contract.methods.adminWithdrawal().estimateGas({ from: BUYBACK_CONTRACT_OWNER });
            if (contract.methods) {
                contract.methods.adminWithdrawal()
                    .send({ from: BUYBACK_CONTRACT_OWNER, gasPrice, gas })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        resolve(error);
                    })
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            reject(error);
        }
    });
};


const getTokenAllowance = async (data) => {
    console.log('getTokenAllowance -- ', data)
    let maxlimit = BigNumber(10).power(40)
    let maxlimit1 = maxlimit.toString()


    const myDynamicContractInstance = await new web3Instance.eth.Contract(
        dynamicContractABI,
        data.tokenAddress
    );
    return new Promise(async (resolve, reject) => {
        try {
            if (myDynamicContractInstance.methods) {
                let limit = data.status ? maxlimit1 : 0
                myDynamicContractInstance.methods
                    .approve(XIVDB_ADDRESS, limit)
                    .send({ from: data.owner_address })
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        console.log('error ', error)
                        reject(error);
                    });
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error', error)
            reject(error);
        }
    });
};

const getTokenAllowanceInfo = async (data) => {
    let myDynamicContractInstance = await new web3Instance.eth.Contract(
        dynamicContractABI,
        data.tokenAddress
    );
    return new Promise(async (resolve, reject) => {
        try {
            let status = await checkConnectToMetaMaskForCall(data.owner_address)
            if (status === false) {
                return false
            }
            if (myDynamicContractInstance.methods) {
                myDynamicContractInstance.methods
                    .allowance(data.owner_address, XIVDB_ADDRESS)
                    .call()
                    .then((result) => {
                        console.log('allowance', result)
                        resolve(result);
                    })
                    .catch(reject);
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error', error)
            reject(error);
        }
    });
};

const checkTokenBalance = async (data) => {
    let myDynamicContractInstance = await new web3Instance.eth.Contract(
        dynamicContractABI,
        data.tokenAddress
    );
    return new Promise(async (resolve, reject) => {
        try {
            if (myDynamicContractInstance.methods) {
                myDynamicContractInstance.methods
                    .balanceOf(data.ownerAddress)
                    .call()
                    .then((result) => {
                        resolve(result);
                    })
                    .catch(reject);
            } else {
                reject(new Error("Contract not found."));
            }
        } catch (error) {
            console.log('error', error)
            reject(error);
        }
    });
};

const checkConnectToMetaMaskForCall = async (address) => {
    if (address) {
        return true
    }
    else {
        return false;
    }
};

export const EthereumService = {

    getCharityAddress,
    getDevAddress,
    getDevShare,
    setDevShare,
    getSwapAllowance,
    changeSwapAllowance,
    getNumMin,
    getBalance,
    getRandomNumber,
    emergencyWithdrawal,
    getNumMax,
    setNumMin,
    setNumMax,
    setNumMinLottery,
    setNumMaxLottery,
    //buyback methods - end

    //charity methods - start
    approveCharity,
    getStakingPhase,
    stopStakingPhase,
    startStakingPhase,
    getCharityRound,
    //charity methods - end

    //FarmPool methods - start
    getPoolInfo,
    addFarmPool,
    //FarmPool methods - end

    //MultiToken methods - start
    addToken,
    blackListToken,
    whiteListToken,
    //MultiToken methods - end

    callWeb3,
    getTokenAllowance,
    getTokenAllowanceInfo,
    checkTokenBalance,
    
};
