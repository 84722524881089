import { fetch } from "./Fetch";
import { API_HOST } from "../constant";

const addFunction = (data) => fetch("post", `${API_HOST}pair/addFunction`, data);
const updateFunction = (data) => fetch("post", `${API_HOST}pair/updateFunction`, data);
const getInfo = (pageNo, limit) => fetch("post", `${API_HOST}pair/getInfo`, { pageNo, limit });


export const AdminService = {
  addFunction,
  getInfo,
  updateFunction
};
