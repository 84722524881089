import { USER_ADDRESS ,USER_BALANCE,USER_THEME} from "../actions/user.action";

const initialState = {
  metaMaskAddress: "",
  balance: 0,
  lightMode:false
};

export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case USER_ADDRESS:
      return { ...state, metaMaskAddress: payload };
    case USER_BALANCE:
      return { ...state, balance: payload };
      case USER_THEME:
      return { ...state, lightMode: payload };
    default:
      return state;
  }
} 