import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_SUCCESS,
  METAMASK_WALLET_ADDRESS,
} from "./../constants";

import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../Components/Toast/Toast";
import { ContractServices } from "../../services/ContractServices";
import { MAIN_CONTRACT_LIST } from "../../assets/tokens";

export const adminLogin = (history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const metamskAddress = await getMetaMaskAddress();
    const contract = await ContractServices.callContract(MAIN_CONTRACT_LIST.timeLock.address, MAIN_CONTRACT_LIST.timeLock.abi);
    const ownerAddress = await contract.methods.admin().call();

    if (metamskAddress.toLowerCase() === ownerAddress.toLowerCase()) {
      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
      });
      dispatch(stopLoading());
      // history.push("/admin-dashboard");
      history.push("/admin-dashboard/Tables");
      // toast.success("Logged in successfully!");

    } else {
      toast.error("Please Connect to Correct MetaMask");
      dispatch({
        type: ADMIN_LOGIN_FAIL,
      });
      dispatch(stopLoading());

    }
  } catch (error) {
    console.log("Error:", error);
    dispatch(stopLoading());
    toast.error("Something went wrong");
    dispatch({
      type: ADMIN_LOGIN_FAIL,
    });
  }
};

export const adminLogOut = (history) => async (dispatch) => {
  dispatch({
    type: ADMIN_LOGIN_FAIL,
  });
  history.push("/admin");
};

export const isMetaMaskInstalled = async () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum, web3 } = window;
  const result = Boolean(ethereum && ethereum.isMetaMask);
  if (result) {
    return "metamask";
  } else if (web3) {
    return "trustwallet";
  } else {
    return "none";
  }
};

export const connectMetamask = () => async (dispatch, getState) => {
  const installed = await isMetaMaskInstalled();
  try {
    let address;
    if (installed === "metamask") {
      const { ethereum } = window;
      ethereum
        .enable()
        .then(async (accounts) => {
          address = accounts[0];
          return dispatch({ type: METAMASK_WALLET_ADDRESS, payload: address });
        })
        .catch((err) => console.log(err));
    } else {
      return toast.error("Please connect metamask");
    }
  } catch (error) {
    return toast.error(error.message);
  }
};

export const getMetaMaskAddress = async () => {
  const installed = await isMetaMaskInstalled();
  try {
    if (installed === "metamask") {
      const { ethereum } = window;
      return ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(async (accounts) => {
          return accounts[0];
        })
        .catch((err) => console.log(err));
    } else {
      return "0x0000000000000000000000000000000000000000"
    }
  } catch (error) {
    return "0x0000000000000000000000000000000000000000" 
  }
};