export const rootName = "/inverse";
export const STAKING_CONTRACT_ADDRESS =
  "0x49D2F3ef26690e77a5B427C8e84617Ad57959DFC";
export const DEFI_INDEX_FIXED_BET_CONTRACT_ADDRESS =
  "0x0000000000000000000000000000000000000000";
export const USDT_TOKEN_ADDRESS = "0xBbf126a88DE8c993BFe67c46Bb333a2eC71bC3fF";

//export const API_HOST = "http://localhost:3001/api/";
export const API_HOST = "https://ws.niob.app/api/";
//export const API_HOST = "https://ws.stage-aquagoat.xyz/api/api/"
//export const API_HOST = "http://10.1.7.60:3001/api/";


export const NETWORK = "bsc tesnet";
export const PRICE_CONTRACT_ADDRESS =
  "0x70A89e52faEFe600A2a0f6D12804CB613F61BE61";
export const XIV_TOKEN_ADDRESS = "0x44f262622248027f8E2a8Fb1090c4Cf85072392C";
export const BSC_XIV_TOKEN_ADDRESS =
  "0xa75e2bee339f270a0ef1f12a3d71342d6f9ed7de";
export const XIV_QUICKSWAP_ADDRESS =
  "0xde799636aF0d8D65a17AAa83b66cBBE9B185EB01";
export const XIVDB_ADDRESS = "0x09eD6f016178cF5Aed0bda43A7131B775042a3c6";
export const XIVMAIN_ADDRESS = "0xD0dF7026cA99c7CF439Ee8F3eF68cf34746b13dE";
export const XIVBettingFlexible_ADDRESS =
  "0xBe081De3462289b152D60e7e70B25d86AC8425Eb";
export const XIVBettingFixed_ADDRESS =
  "0xe208B7b5091e90f5e07BD0c098B1810E9b2713aC";
// export const INVERSE = "0x1B139a17f17739FCbBa414Abc1cb5aa49A51610c";
export const INVERSE = "0xF445219c6498a00c9EDd716B09f4Da0a2c41C4AA";
export const INVERSE_OWNER = "0xf240D8E0B9Cb493b380791b65CAFEef62080E5A1";
export const ORACLE_WRAPPER = "0xfb22599bb4c082631b619bd4ac1a7d81eaa996b7";

//AquaGoat 
export const BUYBACK_CONTRACT_ADDRESS = "0xD4cB076f9F0904943C12974405881fbE96adc310";
export const BUYBACK_CONTRACT_OWNER = "0xa74E3ae01B9d2E3C3dAe9556d7625f2485642812";
export const CHARITY_CONTRACT_ADDRESS = "0x3bF4423643B45953a0E17070e8de1AC3fc135bF4";
export const FARM_POOL_CONTRACT_ADDRESS = "0x5e4e9F65333c3D5304B39a154bf30C2F44326aFf";
export const MULTI_TOKEN_CONTRACT_ADDRESS = "0x597Cf5F06919aBE4b6E215A73Fc49b16e637cbcD";
export const WETH = "0xc778417E063141139Fce010982780140Aa0cD5Ab";
export const FACTORY_CONTRACT_ADDRESS = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
export const SINGLE = "single";
export const MULTIPLE = "multiple";
export const MASTERCHEF = "0xF9fB694e6a1Ea6cD2c579652c1CD8ACe3ba253e9";
//AquaGoat

export const tokenList = [
  {
    symbol: "ETH",
    index: 0,
    contract_address: "",
    type: "currency",
    decimal: 10 ** 18,
  },
  {
    symbol: "USDT",
    index: 1,
    contract_address: "0xBbf126a88DE8c993BFe67c46Bb333a2eC71bC3fF",
    type: "token",
    decimal: 10 ** 8,
  },
];

export const MASTERCHEF_INPUTS = [
  {
    "user": "",
    "pid": "",
    "amount": ""
  },
  {
    "user": "",
    "pid": "",
    "amount": ""
  },
  {
    "caller": "",
    "previousAmount": "",
    "newAmount": ""
  },
  {
    "previousOwner": "",
    "newOwner": ""
  },
  {
    "user": "",
    "referrer": "",
    "commissionAmount": ""
  },
  {
    "user": "",
    "pid": "",
    "amountLockedUp": ""
  },
  {
    "user": "",
    "pid": "",
    "amount": ""
  },
  {
    "_allocPoint": "",
    "_lpToken": "",
    "_depositFeeBP": "",
    "_harvestInterval": "",
    "_withUpdate": "",
    "_type": ""
  },
  {
    "_pid": "",
    "_amount": "",
    "address": ""

  },
  {
    "_pid": ""

  },
  {
    "empty": ""
  },
  {
    "empty": ""
  },
  {
    "empty": ""
  },
  {
    "_pid": "",
    "_allocPoint": "",
    "_depositFeeBP": "",
    "_harvestInterval": "",
    "_withUpdate": "",
    "_type": ""
  },
  {
    "_devAddress": ""

  },
  {
    "_feeAddress": ""

  },
  {
    "_lpToken": "",
    "_type": ""
  },
  {
    "_rabbitReferral": ""

  },
  {
    "_referralCommissionRate": ""
  },
  {
    "newOwner": ""
  },
  {
    "_rabbitPerBlock": ""
  },
  {
    "_pid": ""

  },
  {
    "_pid": "",
    "_amount": ""
  }
];
