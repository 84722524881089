import BNB from "../../Images/token_icons/BNB.svg";
import ETH from "../../Images/token_icons/ETH.svg";
import ADA from "../../Images/token_icons/ADA.svg";
import BTC from "../../Images/token_icons/BTC.svg";
import CAKE from "../../Images/token_icons/CAKE.svg";
import POLKADOT from "../../Images/token_icons/POLKADOT.svg";
import TRON from "../../Images/token_icons/TRON.svg";
import ANCHOR from "../../Images/token_icons/ANCHOR.svg";
import BUSD from "../../Images/token_icons/BUSD.svg";
import defaultImg from "../../Images/token_icons/default.svg";
import routerABI from "../ABI/router.ABI.json";
import farmABI from "../ABI/farmABI.json";
import factoryABI from "../ABI/factory.ABI.json";
import pairABI from "../ABI/pair.ABI.json";
import referralsABI from "../ABI/referrals.ABI";
import lotteryABI from "../ABI/lottery.ABI.json";
import NFTABI from "../ABI/NFT.ABI.json";
import tokenABI from "../ABI/tokenContract.ABI.json";
import multiTokenLottary from "../ABI/multiTokenLottary.json";
import multiTokenNFT from "../ABI/multiTokenNFT.json";
import timelockABI from "../ABI/timelock.json";

export const WETH = "0x31BBA4f3f2Ec07Db6CFB2D959C72CcAB2bFD0e8A";
export const BURN_ADDRESS = "0x000000000000000000000000000000000000dEaD";
export const ANCHOR_BUSD_LP = "0xC0Ff9f250d2D97F90BC89bD16D3B5344CdC68d06";
export const BNB_BUSD_LP = "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16";
export const DEFLATIONNARY_TOKENS = ["0x1f546ad641b56b86fd9dceac473d1c7a357276b7", '0x4aac18De824eC1b553dbf342829834E4FF3F7a9F', "0x0ED224e1d088c1BA17BdF352D4FaF0979E7BB0b7"];
export const OVERVIEW_LINK = "https://bscscan.com/token/0x4aac18De824eC1b553dbf342829834E4FF3F7a9F";
export const TOKEN_LINK = "https://bscscan.com/address/0x4aac18De824eC1b553dbf342829834E4FF3F7a9F";
export const BUSD_LP = "https://bscscan.com/address/0xC0Ff9f250d2D97F90BC89bD16D3B5344CdC68d06";
export const BNB_LP = "https://bscscan.com/address/0x942986B6Cbe26a80a5456D5d3Ac75860f0E9546e";
export const AUTOMATIC_LIQUIDITY = "https://docs.anchorswap.finance/tokenomics/#automatic-liquidity";
export const LOTTERY = "https://docs.anchorswap.finance/products/#lottery";
export const ROADMAP = "https://docs.anchorswap.finance/roadmap/#roadmap";
export const AUTOMATIC_BURNING = "https://docs.anchorswap.finance/tokenomics/#automatic-burning";
export const HARVEST_LOCKUP = "https://docs.anchorswap.finance/tokenomics/#harvest-lockup";
export const ANTI_WHALE = "https://docs.anchorswap.finance/tokenomics/#anti-whale";

export const TOKEN_LIST = [
  {
    icon: BNB,
    name: "BNB",
    address: "BNB",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "BNB",
  },
  {
    icon: ANCHOR,
    name: "ANCHOR",
    address: "0x4aac18De824eC1b553dbf342829834E4FF3F7a9F",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "ANCHOR",
  },
  {
    icon: BUSD,
    name: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "BUSD",
  },
  {
    icon: ETH,
    name: "ETH",
    address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "ETH",
  },
  {
    icon: ADA,
    name: "Cardano",
    address: "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "ADA",
  },
  {
    icon: defaultImg,
    name: "Matic Token",
    address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "MATIC",
  },
  {
    icon: defaultImg,
    name: "Dai Token",
    address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "DAI",
  },
  {
    icon: BTC,
    name: "BTCB Token",
    address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "BTCB",
  },
  {
    icon: defaultImg,
    name: "Tether USD",
    address: "0x55d398326f99059fF775485246999027B3197955",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "USDT",
  },
  {
    icon: POLKADOT,
    name: "Polkadot Token",
    address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "DOT",
  },
  {
    icon: TRON,
    name: "TRON",
    address: "0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "TRX",
  },
  {
    icon: CAKE,
    name: "PancakeSwap Token",
    address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "Cake",
  },
  {
    icon: BNB,
    name: "Wrapped BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    isAdd: false,
    isDel: false,
    decimals: 18,
    symbol: "WBNB",
  }
];

export const MAIN_CONTRACT_LIST = {
  lottary: {
    address: "0xc1E3E99A94Fb9F6B192a96347090F2BcDf017320",
    blockNumber: 12385818,
    abi: lotteryABI,
  },
  NFT: {
    address: "0xd2F30Edffba7F41c145758B78e969C01146076D4",
    blockNumber: 12385785,
    abi: NFTABI,
  },
  farm: {
    address: "0x23f7F3119d1b5b6c94a232680e2925703C4ebbF5",
    blockNumber: 10004492,
    abi: farmABI,
  },
  pool: {
    address: "",
    abi: "",
    blockNumber: 0,
  },
  router: {
    address: "0x5f5637bCF4D76c6E944d90dD447c2Cb9E17a2943",
    blockNumber: 12555656,
    abi: routerABI,
  },
  pair: {
    address: "",
    blockNumber: 0,
    abi: pairABI,
  },
  referrals: {
    address: "0x42da818171a8b58A98771F4b99Ea0175f9f7BFc7",
    blockNumber: 10004593,
    abi: referralsABI,
  },
  anchor: {
    address: "0x4aac18De824eC1b553dbf342829834E4FF3F7a9F",
    blockNumber: 10004070,
    abi: tokenABI,
  },
  aquaGoat: {
    address: "0x604c178D7E32855b561d5045d6978f24524850D9",
    blockNumber: 9723360,
    abi: tokenABI,
  },
  multiTokenLottary: {
    address: "0x0101c51E157dCc7FbA58B83c906b6D02EAbE89b2",
    blockNumber: '',
    abi: multiTokenLottary,
  },
  multiTokenNFT: {
    address: "0x822BACBC09c7C7F538BFa03397d349101608C88c",
    blockNumber: 12522239,
    abi: multiTokenNFT,
  },
  factory: {
    address: "0xA5e7015DEf56D3fC020a3099B3597701a9A09407",
    blockNumber: '',
    abi: factoryABI,
  },
  timeLock: {
    address: "0xeD506441F2E3AE3d2ECB5F1Bb7155C826f1c0198",
    blockNumber: 10004070,
    abi: timelockABI,
  }
};
