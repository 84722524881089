import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./tables.scss";
import ButtonCustom from "../../../../Components/ButtonCustom/ButtonCustom";
import { Container, Row, Table } from "react-bootstrap";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { AdminService } from "../../../../services/AdminService";
import { executeTransaction, cancelTransaction } from "../../../../redux/actions/admin.action";

const Tables = () => {
  const dispatch = useDispatch();
  const [factory, setFunctionDetails] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);


  const handlePageChange = (pageNumber) => {
    const pageNo = pageNumber - 1;
    console.log(pageNumber);
    setCurrentPage(pageNumber);
    let offset = 0;
    if (pageNumber != 1) {
      offset = (pageNo * limit);
    }

    setPageNo(offset);
  }

  const handelExecuteTransaction = (data) => {

    dispatch(executeTransaction(data));
  }
  const handelCancelTransaction = (data) => {
    dispatch(cancelTransaction(data));
  }
  const checkTimeForExecuteAndCancel = (time) => {
    let queuedDate = new Date(time);
    queuedDate.setSeconds(queuedDate.getSeconds() + 190);
    const currentDate = new Date();
    if (currentDate > queuedDate) return false;
    return true;
  }

  useEffect(async () => {
    await callingFunction();
  }, [pageNo])

  const callingFunction = async () => {
    let result = await AdminService.getInfo(pageNo, limit);
    if (result !== null) {
      setFunctionDetails(result.data.factory);
      setTotalSize(result.data.totalSize);
    }
  }

  const getPramas = (paramsList) => {
    let arr = [];
     
       paramsList.length > 0 && paramsList.map((data, index) => {
         var paramsValue = data.value
         //console.log((paramsValue[41]))

           const pushValue =  paramsValue[41]  ? paramsValue.substring(1,4) +`...`+ paramsValue.substr(paramsValue.length - 4)  : paramsValue;
           arr.push(`<br/>`+pushValue)  
        }
       )
      return arr;
  }

  
  return (
    <>
      <Container className="full-width-container admin-form fadeInLeft">
        <Row>
          <div className="table_responsive w-100">
            <Table>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Name</th>
                  <th>Time</th>
                  <th>Params</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  factory.length > 0 && factory.map((data, index) => (
                  
                    <tr key={index}>
                    
                      <td>{index + 1}</td>
                      <td>{data.name} </td>
                      <td>
                      {/* {new Date(data.time).toDateString()}  */}
                      {new Date(data.time).toUTCString('en-US', { hour: 'numeric', minute: 'numeric',second: 'numeric', hour12: true }).slice(0, -3)}

                      </td>
                      <td dangerouslySetInnerHTML={{__html:getPramas(data.params)}}></td>
                      <td>{data.status}</td>
                      <td>
                        {data.status === 'Inactive' ?
                          <>
                            <span>
                              <ButtonCustom onClick={() => handelCancelTransaction(data)} title="Cancel" className="table_btn" disabled={checkTimeForExecuteAndCancel(data.time)} />
                            </span>
                            <span>
                              <ButtonCustom onClick={() => handelExecuteTransaction(data)} title="Execute" className="table_btn" disabled={checkTimeForExecuteAndCancel(data.time)} />
                            </span></> : ''}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            {factory.length == 0 && <p className="factory_data">{"There are no records!"}</p>}
          </div>
          <div>
            <Pagination
              currentPage={currentPage}
              totalSize={totalSize}
              sizePerPage={limit}
              changeCurrentPage={handlePageChange}
              theme="bootstrap"
            />
            <br />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
