import React, { Component } from "react";
import { withRouter } from "react-router";

class PrivateRoutes extends Component {
  state = {};
  render() {
    return (
      <div className="PrivateArea__content">
       
      </div>
    );
  }
}

export default withRouter(PrivateRoutes);
