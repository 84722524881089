import React from "react";
import {
  Container,
  Row,
  Pagination,
} from "react-bootstrap";
import "./Buyback.scss";


const Index = () => {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }


 

  return (
    <Container className="full-width-container admin-form fadeInLeft">
      <Row>Dashboard</Row>
    </Container>
  );
};

export default Index;
