import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const renderMergedProps = (component, props, ...rest) => {
  const finalProps = Object.assign({}, ...rest);

  return React.createElement(component, finalProps);

};

const AuthGuard = ({ component: Component, ...rest }) => {


  const isAuthenticated = true;

  return (
    <Route
      {...rest}
      render={props =>

        isAuthenticated ?
         
          (
            renderMergedProps(Component, props, rest)
          )
          : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          )
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    token: true
  };
};

export default connect(
  mapStateToProps,
  null
)(AuthGuard);
