import React, { useState } from "react";
import "./popup.scss";
import { Button, FormControl, Modal } from "react-bootstrap";

const Index = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow} className="admin_btn">
        Popup
      </Button>

      <Modal className="admin_modal" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <FormControl></FormControl>
        <Modal.Footer>
          <Button className="btn_modal" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn_modal" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;
