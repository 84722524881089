import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_SUCCESS,
  START_LOADING,
  STOP_LOADING,
} from "./../constants";

const initialState = {
  adminAddress: null,
  walletAddress: null,
  isLoggedIn: false,
  isLoading: false
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        adminAddress: payload,
        isLoggedIn: true
      };
    case ADMIN_LOGIN_FAIL:
      return {
        ...state,
        adminAddress: null,
        isLoggedIn: false
      };
    case START_LOADING:
        return {
          ...state,
          isLoading: true
        };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
};

export default adminReducer;
